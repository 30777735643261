<template>
    <div v-if="toasts.length > 0">
        <div
                v-for="(toast, index) in toasts"
                :key="toast.id"
        >
            <div
                    :style="getToastStyle(index)"
                    aria-live="assertive"
                    class="layer select-none pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 truncate"
            >
                <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
                    <transition
                            enter-active-class="transform ease-out duration-800 transition"
                            enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                            leave-active-class="transform ease-in duration-800 transition"
                            leave-from-class="opacity-100"
                            leave-to-class="opacity-0"
                            @after-leave="removeToast(toast)"
                    >
                        <div
                                class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-[#2f201b] shadow-[0_0px_20px_-1px_rgba(194,134,76,1)] backdrop-blur-xl ring-1 ring-black ring-opacity-5 hover:bg-[#4f3f3a]"
                                title=""
                                data-aos="fade-left"
                                data-aos-delay="200"
                        >
                            <div class="p-4" title="">
                                <div class="flex items-start" title="">
                                    <div class="flex-shrink-0" title="">
                                        <CheckCircleIcon v-if="toast.type === 'success'" aria-hidden="true" class="h-6 w-6 text-green-400" />
                                        <XCircleIcon v-if="toast.type === 'error'" aria-hidden="true" class="h-6 w-6 text-red-400" />
                                        <InformationCircleIcon
                                                v-if="toast.type === 'info'"
                                                aria-hidden="true"
                                                class="h-6 w-6 text-blue-400"
                                        />
                                    </div>
                                    <div class="ml-3 w-0 flex-1 pt-0.5" title="">
                                        <p class="text-sm font-medium text-gray-100" title="">
                                            {{ toast.title }}
                                        </p>
                                        <p class="mt-1 text-sm text-gray-500" title="">
                                            {{ toast.message }}
                                        </p>
                                    </div>
                                    <div class="ml-4 flex flex-shrink-0" title="">
                                        <button
                                                title=""
                                                class="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none transition-transform duration-400 hover:rotate-180"
                                                type="button"
                                                @click="removeToast(toast.id)"
                                        >
                                            <span class="sr-only" title="">Close</span>
                                            <XMarkIcon aria-hidden="true" class="h-5 w-5" title="" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { CheckCircleIcon, XCircleIcon, InformationCircleIcon } from '@heroicons/vue/24/outline';
import { XMarkIcon } from '@heroicons/vue/20/solid';

const { toasts, removeToast } = useToasts();

const getToastStyle = (index: number) => {
    const offsetY = 100;
    const translateY = index * offsetY;
    return `transform: translateY(${translateY}px)`;
};

</script>

<style scoped>
.layer {
    z-index: 9999;
}
</style>
